import React from 'react';
import styled from 'styled-components';

import * as s from '../setup/styles/globalStyles';

const Page = styled(s.Page)`
    grid-template-rows: 96px 1fr 1fr 1fr;
    background: #000;
    background-image: url('/bg-2.png');
    background-size: Contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    position: relative;
    
    @media (max-width: 850px) {
      grid-template-rows: 96px 1fr 2fr 1fr;
    }

    @media (max-width: 480px) {
      grid-template-rows: 96px 1fr 3fr;
    }
`;

const Column = styled(s.Column)`
  align-items: flex-start;
  gap: unset;
`;

const Astronauts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: row;
  }
`;

const Astronaut = styled.img`
  pointer-events: none;
  max-width: 120px;
  object-fit: contain;

  @media (min-width: 1800px) {
    max-width: 160px;
  }

  @media (max-width: 850px) {
    max-width: 120px;
  }

  @media (max-width: 480px) {
    max-width: 80px;
  }
`;

const Text = styled.span`
  line-height: 1.6;
  font-weight: 600;
  font-size: 24px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;


  @media (min-width: 1800px) {
    font-size: 32px;
  }

  @media (max-width: 850px) {
    flex-direction: row;
  }
`;

const Intro = () => {

  return (
    <Page id="intro">
      <Header />

      <Text>
        1000 unique 3D characters
      </Text>

      <Astronauts>
        <Astronaut src='astronaut-1.png' />
        <Astronaut src='astronaut-2.png' />
        <Astronaut src='astronaut-3.png' />
        <Astronaut src='astronaut-4.png' />
        <Astronaut src='astronaut-5.png' />
        <Astronaut src='astronaut-6.png' />
        <Astronaut src='astronaut-7.png' />
        <Astronaut src='astronaut-8.png' />
        <Astronaut src='astronaut-9.png' />
        <Astronaut src='astronaut-10.png' />
      </Astronauts>
    </Page>
  )
}

const Header = () => {

  return (
    <Column>
      <s.Row>
        <s.Title>
          Astronauts
        </s.Title>
      </s.Row>
      <s.Row>
        <s.Subtitle>
          First edition
        </s.Subtitle>
      </s.Row>
    </Column>
  )
}

export default Intro;
