import React from 'react';
import styled from 'styled-components';

import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 96px auto 96px;
  background: #000;
  background-image: url('/bg-3.png');
  background-size: Contain;
  background-repeat: no-repeat;
  position: relative;
  padding: 2em 6em 0em 6em;

  @media (max-width: 850px) {
    padding: 2em 2em 0em 2em;
  }
`;

const Column = styled(s.Column)`
  align-items: flex-start;
  gap: unset;
`;

const Row = styled(s.Row)`
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  position: relative;
  backdrop-filter: blur(5px);
  border-radius: 50px;
  box-shadow: rgb(255 255 255 / 16%) 0px 1px 4px;
  justify-content: center;
  align-items: center;
  opacity: 0.6;

  @media (min-width: 1800px) {
    font-size: 28px;
  }
`;

const Minter = () => {

  return (
    <Page id="minter">
      <Header />
      <Container>
        Comming soon
      </Container>

      <s.Footer>
        © 2022 Hasbik - token@hasbik.net
      </s.Footer>
    </Page>
  )
}

const Header = () => {

  return (
    <Column>
      <Row>
        <s.Title>
          Minter
        </s.Title>
      </Row>
    </Column>
  )
}

export default Minter;
