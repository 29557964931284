import styled from 'styled-components';

export const Page = styled.section`
  display: grid;
  min-height: 100vh;
  padding: 2em 6em 2em 6em;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1920px;

  @media (max-width: 850px) {
    padding: 2em 2em 2em 2em;
  }
`;

export const Pages = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div`
  overflow: 'hidden'
  position: relative;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 3em;
  }
`;

export const Column = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  gap: 2em;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;

  @media (min-width: 1800px) {
    font-size: 44px;
  }
`;

export const Subtitle = styled.span`
  opacity: 0.6;
  line-height: 1.6;

  @media (min-width: 1800px) {
    font-size: 28px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 3em;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  font-size: 0.8em;
  aligh-items: center;

  @media (min-width: 1800px) {
    font-size: 24px;
  }
`;
