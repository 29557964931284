import React from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import Button from '../components/shared/Button';

import * as s from './../setup/styles/globalStyles';

const Page = styled(s.Page)`
  grid-template-rows: 64px 2fr 2fr;
  background-image: url('/bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  position: relative;

  @media (max-width: 850px) {
    grid-template-rows: 64px 2fr 2fr;
  }

  @media (max-width: 480px) {
    grid-template-rows: 64px 2fr 3fr;
  }
`;

const Content = styled(s.Row)`
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 850px) {
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const TitleWrapper = styled(s.Column)`
  align-items: flex-start;
  justify-content: flex-start;
  gap: unset;
`;

const NavigationWrapper = styled(s.Column)`
  align-items: flex-end;
  justify-content: flex-start;
`;

const Clock = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: center;
  color: #daf6ff;
  text-shadow: 0px 0px 20px rgba(255, 146, 0, 1);
`;

const Time = styled.p`
  letter-spacing: 0.05em;
  font-size: 64px;
  padding: 5px 0;
  margin: 0px;

  @media (min-width: 1800px) {
    font-size: 112px;
  }

  @media (max-width: 480px) {
    font-size: 48px;
  }
`

const ReleaseDate = styled.p`
  letter-spacing: 0.1em;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 8px;

  @media (min-width: 1800px) {
    font-size: 32px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #fff;
  opacity: 0.9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-size: 16px;

  @media (min-width: 1800px) {
    font-size: 28px;
  }
`

const Paragraph = styled.p`
  width: 50%;
  opacity: 0.4;
  line-height: 1.6;
  font-weight: 600;
  margin: 0;

  @media (min-width: 1800px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

const MobileButtons = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: unset;
  }
`;

const Home = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Comming soon</span>;
    } else {
      return <Clock><ReleaseDate>2022-07-01 FRI</ReleaseDate><Time>{days < 10 ? `0${days}` : days}:{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Time></Clock>;
    }
  };

  return (
    <Page>
      <Header />

      <Clock>
        <ReleaseDate>
          release date
        </ReleaseDate>
        <Time>
          To Be Announced
        </Time>
        <ReleaseDate
        style={{
          marginTop: '8px',
        }}
        >
          (soon, really)
        </ReleaseDate>
      </Clock>

      <Content>
        <Paragraph>
          A generative playable NFT collection that gives you access into the Hasbik’s metaverse. NFTs are powered by the HAS token and serve as both identity and access key to enter different realms.
        </Paragraph>

        <MobileButtons>
          <Button
            background='transparent'
            color='#fff'
            icon={'/discord-logo-white.png'}
            link={'https://discord.gg/VC54YrS5S2'}
            text={'Join'}
          />
        </MobileButtons>
      </Content>
    </Page>
  )
}

const Header = () => {

  return (
    <s.Row>
      <TitleWrapper>
        <s.Row>
          <s.Title>
            Hasbik Surrogates
          </s.Title>
        </s.Row>
        <s.Row>
          <s.Subtitle>
            Your metaverse character
          </s.Subtitle>
        </s.Row>
      </TitleWrapper>

      <NavigationWrapper>
        <s.Navigation>
          <Link href="#intro">Astronauts</Link>
          <Link href="#minter">Minter</Link>

          <Button
            background='transparent'
            color='#fff'
            icon={'/discord-logo-white.png'}
            link={'https://discord.gg/VC54YrS5S2'}
            text={'Join'}
          />
        </s.Navigation>
      </NavigationWrapper>
    </s.Row>
  )
}

export default Home;
