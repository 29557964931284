import React from 'react';
import Home from './pages/Home';
import Intro from './pages/Intro';
import Minter from './pages/Minter';

import * as s from './setup/styles/globalStyles';

function App() {

  return (
    <s.Pages>
      <Home />
      <Intro />
      <Minter />
    </s.Pages>
  );
}

export default App;
